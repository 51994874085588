<template>
  <r-dialog :value="value">
    <v-card-text class="text--primary text-body-1 pt-4 pb-3">
      <h4 class="pt-2 text-center">
        Oops! Looks like you will need Resumation Plus to
        {{ restrictedFeaturePhrase }}!
      </h4>
      <p class="mt-2">
        Upgrade to get unlimited use of the Optimize with AI features, hide/show
        parts of your resume in-tool (customizations), save and store multiple
        resumes, and much more for only $5 a month!
      </p>
      <h4 class="pt-2 text-center">
        Would you like to upgrade to Resumation Plus?
      </h4>
    </v-card-text>
    <v-card-actions
      :class="
        $vuetify.breakpoint.xsOnly
          ? 'flex-wrap justify-center'
          : 'flex-wrap justify-end'
      "
    >
      <v-btn color="gray" outlined text @click="updateValue(false)">
        Not Now
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined text @click="navToPremiumPage()">
        Yes - let's do it!
      </v-btn>
    </v-card-actions>
  </r-dialog>
</template>
<script>
export default {
  name: "PremiumBanner",
  props: {
    value: {
      type: Boolean,
    },
    restrictedFeaturePhrase: {
      type: String,
      default: "use this feature",
    },
  },
  methods: {
    navToPremiumPage() {
      this.dialog = false;
      this.$router.push({ path: "/Upgrade" });
    },
    updateValue: function(value) {
      this.$emit("input", value);
    },
  },
};
</script>
